<template>
    <div>
        <a-card style="background-color: #fff;" v-if="hasPermission('QUERY_CAKE_MAILL')">
            <div :class="advanced ? 'search' : null">
                <a-form layout="horizontal">
                    <div :class="advanced ? null : 'fold'">
                        <a-row>
                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="品牌名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-input v-model="queryData.name" placeholder="品牌名称/关键词" />
                                </a-form-item>
                            </a-col>
                            <!-- <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="城市" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-input v-model="queryData.city" placeholder="请输入城市" />
                                </a-form-item>
                            </a-col> -->

                            <a-col :md="8" :sm="24" style="max-width: 400px;">
                                <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <a-range-picker :value="dateValue" :ranges="{
                                        '今天': [moment(), moment()],
                                        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                     '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                    }" @change="dateChange" />
                                </a-form-item>
                            </a-col>
                            <a-col :md="6" :sm="24" style="max-width: 400px;">
                                <a-form-item label="配送地址" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                    <div style="display: flex;margin-top: 6px;">
                                        <a-select allowClear @change="provChange"
                                        v-model="searchProv"
                                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                                            show-search placeholder="省" option-label-prop="label">
                                            <a-select-option v-for="item in prov" :key="item.id" :value="item.id"
                                                :label="item.name">{{ item.name }}</a-select-option>
                                        </a-select>
                                        <a-select @change="cityChange" allowClear placeholder="市"
                                        v-model="searchCity"
                                            :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                                            show-search option-label-prop="label">
                                            <a-select-option v-for="item in city" :key="item.id" :value="item.name"
                                                :label="item.name">{{ item.name }}</a-select-option>
                                        </a-select>
                                    </div>
                                </a-form-item>
                            </a-col>
                            <a-col :md="8" :sm="24">
                                <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                    @click="search">查询</a-button>
                                <a-button v-if="hasPermission('EXPORT_CAKE_MAILL')" type="primary"
                                    style="margin-top: 4px;margin-left: 10px;"
                                    @click="exportTask">导出</a-button>
                            </a-col>
                        </a-row>
                        <div style="margin-top: 0px;font-size: 15px;margin-left: 10px;">显示第 {{ total>0 ? 1 : 0 }} 至 {{ queryData.size > total ?  total : queryData.size }} 项结果，共 {{ total }} 项</div>
                    </div>

                </a-form>

            </div>


            <div>
                <standard-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                    <div slot="createTime" slot-scope="{text}">{{ formatDate(text) }}</div>
                    <div slot="status" slot-scope="{text}">
                        <span v-if="text == 1" style="color: #008000;">上架</span>
                        <span v-if="text == 2" style="color: #ff0000;">下架</span>
                    </div>
                    <div slot="icon" slot-scope="{text}"><img style="width: 45px;" :src="text" alt=""></div>

                </standard-table>
                <div class="page-box">
                    <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                        :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                        show-size-changer />
                </div>
            </div>
        </a-card>

        <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
            <a-empty :description="false" />
            无权限查看
        </div>
    </div>
</template>
  
<script>
import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { selectCitymixins } from '@/mixin/selectCity'
import { publicMixin } from '@/mixin/public';
const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center' },
    {
        title: '品牌名称',
        dataIndex: 'name',  
        align: 'center'

    },
    {
        title: 'icon',
        dataIndex: 'icon',
        scopedSlots: { customRender: 'icon' },
        align: 'center'

    },
    {
        title: '产品数量',
        dataIndex: 'productNumber',
        align: 'center'
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
        align: 'center'
    },
    {
        title: '状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
        align: 'center'
    },
    // {
    //     title: '操作',
    //     scopedSlots: { customRender: 'action' },
    //     align: 'center'
    // }
]

export default {
    name: 'QueryList',
    components: { StandardTable },
    mixins: [selectCitymixins,publicMixin],
    data() {
        return {
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue:'',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
                name: '',
                status:1,
                cityList: []
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            currentPage: 1,
            searchProv:[],
            searchCity:[]

        }
    },
    authorize: {
        deleteRecord: 'delete'
    },
    mounted() {
        this.getData()
    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        }
    },
    methods: {
   
        provChange(id) {
            this.city = []
            this.queryData.cityList = []
            if (!id) {
                return false;
            }
            request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
                if (res.data.code == 200) {
                    this.city = res.data.data;
                    this.city.forEach(item => {
                        this.queryData.cityList.push(item.name)
                    })
                } else {
                    this.$message.error(res.data.message);
                }
            })
        },
        cityChange(name) {
            if (name) {
                this.queryData.cityList = [name]
            } else {
                this.queryData.cityList = []
                this.city.forEach(item => {
                    this.queryData.cityList.push(item.name)
                })
            }
        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page:0,
                status:1,
                size:10
            }
            this.dateValue =''
            this.searchCity=[];
            this.searchProv=[];
            this.getData()
        },
        search(){
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/brand/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },

        deleteRecord(key) {
            this.dataSource = this.dataSource.filter(item => item.key !== key)
            this.selectedRows = this.selectedRows.filter(item => item.key !== key)
        },
        toggleAdvanced() {
            this.advanced = !this.advanced
        },

    }
}
</script>
  
<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>
  